import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import '../../assets/scss/sub-1.css'
import bannerImg from '../../assets/img/Greetings/GreetingsBanner.png'
import img01 from '../../assets/img/History/history01_03.png'
import img02 from '../../assets/img/History/history02.png'
import img03 from '../../assets/img/History/history03_02.png'
import img03mo from '../../assets/img/History/history03.png'

import logobtn from '../../assets/img/History/logobtn.png'
import Contact from '../../pages/Contact/Contact'
import square from '../../assets/img/Work01/square.png'
import btn from '../../assets/img/Work01/work01btn.png'

function Contents() {
  const [isActive, setActive] = useState(false)

  const style = {
    logoImg: {
      backgroundImage: `url(${bannerImg})`,
    },
    imgSize: {
      backgroundImage: `url(${img01})`,
      height: '10px',
      width: '10px',
    },
    spanStyle: {
      width: '2%',
      height: '90%',
      paddingBottom: '1%',
    },
    fontsize: {
        marginTop: '-3%',
        paddingLeft: '4.5%',
    },
  }

  useEffect(() => {
    setTimeout(() => {
      setActive(true)
    }, 50)
  }, [])

  return (
    <div>
      <Helmet>
        <script type="text/javascript" src="/jquery-3.3.1.js"></script>
        {/* <script src="css/assets/jss/jquery.mCustomScrollbar.css"></script> */}
        <script type="text/javascript" src="/jquery-migrate-1.4.1.js"></script>
        <script
          type="text/javascript"
          src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.1.1/jquery.min.js"
        ></script>
        {/* <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-cookie/1.4.1/jquery.cookie.js"></script> */}
        <script type="text/javascript" src="/_sub-side-menu.js"></script>
      </Helmet>
      {/* <section className="subvisu-sec visu-top-gap "> */}
      <section
        className={`subvisu-sec visu-top-gap ${isActive ? 'start' : ''}`}
      >
        <div className="visu-bg" style={style.logoImg}></div>
        <div className="left-bg"></div>
        <div className="right-bg"></div>
        <p>SI Professional Partner</p>
        <h4 style={{ color: '#0a35f4' }}>-</h4>
        <h3 style={style.margin}>
          {' '}
          信頼と肯定を誇り持って、価格創出を追求する企業
        </h3>
      </section>
      <section className="cont-sec">
        <div className="con-cont">
          <div className="content-box">
            <div className="left-menu-box">
              <dl id="stick" className="left-menu-dl natural">
                <dt>会社情報</dt>
                <dd>
                  <a href="/greetings">ご挨拶・会社理念</a>
                </dd>
                <dd>
                  <a href="/about">会社概要・組織図</a>
                </dd>
                <dd>
                  <a href="/history">会社沿革・主要取引先</a>
                </dd>
              </dl>{' '}
            </div>
            <div id="contbox" className="right-cont-box">
              <div className="contex-box" >
                <h2>
                  {' '}
                  <span>
                    {' '}
                    <img src={square} className="Square"></img>
                  </span>{' '}
                  会社沿革
                </h2>
                <div
                  className="work04Img01div"
                  style={{ paddingBottom: '2%', marginTop: '-1%' }}
                >
                  <img src={img01} className="work04Img01" />
                </div>
                <a href="https://www.fuva-brain.co.jp/" target="_blank">
                  <img
                    src={logobtn}
                    style={{ position: 'relative' }}
                    className="worklogobtn"
                  />
                </a>
                <h2 style={{marginTop:'1%'}}>
                  <div className="workSquarediv">
                    <img src={square} className="Square"></img>
                  </div>
                  <div className="workTitlediv">
                    {' '}
                    主要取引先
                  </div>
                </h2>
                <div>
                  <h3 style={style.fontsize}>
                    {' '}
                    システムで実現する目的、お客様が求める要件を正確に把握分析し、最善のアーキテクチャを提供する。
                    <br></br>
                    その能力を最大限に発揮出来る様、当社では常に、高水準の開発を行っています。
                  </h3>
                </div>
                <div className="work04Img01div">
                  <img src={img02} className="work04Img01" />
                </div>
                <div
                  className="historymo"
                  style={{ marginTop: '10%', marginBottom: '-3%' }}
                >
                  <a href="/contact">
                    <img src={img03mo} className="work04Img03" />
                  </a>
                </div>
                <div className="historydiv" style={{ marginBottom: '-3%' }}>
                  <img src={img03} className="work04Img03" />
                </div>
                <span className="historyText">
                  長期にわたってビジネスパートナーとして、共に歩んでいただける
                  企業様を募集しています。 <br></br>
                  専門性の高い技術やノウハウを保有している企業様と共に、その力を当社と
                  共有する事で、お客様へ付加価値の高いシステムをご提供できると信じています。
                  <br></br>
                  当社に関心をお持ち頂いた方につきましては、メールフォームまたは、
                  <br></br>
                  電話にてご連絡をお願いいたします。<br></br>
                </span>
                <div>
                  <a href="/contact">
                    <img src={btn} className="historyBtn" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="top"></div>
    </div>
  )
}

export default Contents
