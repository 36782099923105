import React from "react";
import {createMemoryHistory} from "history";
import {Route, Router, Switch} from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.4.0";
import 'typeface-roboto';
import 'typeface-roboto-slab';
// pages for this product

import Main from "./Main/Main.jsx";
import Greetings from "./Greetings/Greetings.jsx";
import About from "./About/About.jsx";
import Contact from "./Contact/Contact.jsx";
import History from "./History/History.jsx";
import Work01 from "./Work01/Work01.jsx";
import Work02 from "./Work02/Work02.jsx";
import Work03 from "./Work03/Work03.jsx";
import Recruit01 from "./Recruit01/Recruit01.jsx";
import Recruit02 from "./Recruit02/Recruit02.jsx";
import Recruit03 from "./Recruit03/Recruit03.jsx";
import Access from "./Access/Access.jsx";
import {withPrefix} from "gatsby"
import { Helmet } from 'react-helmet'


let hist = createMemoryHistory();

export default () => (
  <div>
    <Helmet>
        <script src="/_main-board.js"></script>
        <script src="/_main-thum-slide.js"></script>
        <script src="/_sub-side-menu.js"></script>
        <script src="/_main-visu-slider.js"></script>
        <script src="/_today-only-popup.js"></script>
        <script src="/doc.js"></script>
        <script src="/glass.js"></script>
        <script src="/jquery-3.3.1.js"></script>
        <script src="/jquery-migrate-1.4.1.js"></script>
        <script src="/jquery.min.js"></script>
        <script src="/slick.js"></script>
        <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.10.2/jquery.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.1.1/jquery.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-cookie/1.4.1/jquery.cookie.js"></script>
      </Helmet>
    <Router history={hist}>
    <Switch>
      <Route path="/greeting" component={Greetings} />
      <Route path="/about" component={About} />
      <Route path="/history" component={History} />
      <Route path="/contact" component={Contact} />
      <Route path="/work01" component={Work01} />
      <Route path="/work02" component={Work02} />
      <Route path="/work03" component={Work03} />
      <Route path="/recruit01" component={Recruit01} />
      <Route path="/recruit02" component={Recruit02} />
      <Route path="/recruit03" component={Recruit03} />
      <Route path="/access" component={Access} />
      <Route path="/" component={Main} />
    </Switch>
  </Router>
  </div>
  
);
