import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import '../../assets/scss/sub-1.css'
import bannerImg from '../../assets/img/Greetings/GreetingsBanner.png'
import sign from '../../assets/img/Greetings/sign.png'
import main from '../../assets/img/Greetings/greetings.png'
import img01 from '../../assets/img/Greetings/img01.jpg'
import img02 from '../../assets/img/Greetings/img02_02.png'

import square from '../../assets/img/Work01/square.png'
function Contents() {
  const [isActive, setActive] = useState(false)

  const style = {
    logoImg: {
      backgroundImage: `url(${bannerImg})`,
    },
    imgSize: {
      backgroundImage: `url(${img01})`,
      height: '10px',
      width: '10px',
    },
    blue: {
      color: 'blue',
    },
    pSize: {
      fontSize: '15px',
    },
    fontsize: {
      marginTop: '-3%',
      color: '#b3b3b3',
    },
    spanStyle: {
      width: '2%',
      height: '90%',
      paddingBottom: '1%',
    },
  }

  useEffect(() => {
    setTimeout(() => {
      setActive(true)
    }, 50)
  }, [])

  return (
    <div>
      <Helmet>
        <script type="text/javascript" src="/jquery-3.3.1.js"></script>
        <script
          type="text/javascript"
          src="/jquery.mCustomScrollbar.css"
        ></script>
        <script type="text/javascript" src="/jquery-migrate-1.4.1.js"></script>
        {/* <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-cookie/1.4.1/jquery.cookie.js"></script> */}
        <script type="text/javascript" src="/_sub-side-menu.js"></script>
      </Helmet>
      {/* <section clclassNameass="subvisu-sec visu-top-gap "> */}
      <section
        className={`subvisu-sec visu-top-gap ${isActive ? 'start' : ''}`}
      >
        <div className="visu-bg" style={style.logoImg}></div>
        <div className="left-bg"></div>
        <div className="right-bg"></div>
        <p>SI Professional Partner</p>
        <h4 style={{  color: '#0a35f4'}}>-</h4>
        <h3 style={style.margin}>
          {' '}
          自信と誇りを持って、価値創出を追求するSI企業
        </h3>
      </section>
      <section className="cont-sec">
        <div className="con-cont">
          <div className="content-box">
            <div className="left-menu-box">
              <dl id="stick" className="left-menu-dl natural" >
                <dt>会社情報</dt>
                <dd>
                  <a href="/greetings">ご挨拶・会社理念</a>
                </dd>
                <dd>
                  <a href="/about">会社概要・組織図</a>
                </dd>
                <dd>
                  <a href="/history">会社沿革・主要取引先</a>
                </dd>
              </dl>{' '}
            </div>
            <div id="contbox" className="right-cont-box">
              <div className="contex-box">
                <h2 style={{marginBottom:'0%'}}>
                  {' '}
                  <span>
                    <img src={square} className='Square'></img>
                  </span>{' '}
                  ご挨拶
                </h2>
                <div className="work04Img01div">
                  <img src={main} className="work04Img01" />
                </div>
                <div className="greetingdiv">
                  <span className="greetingText">
                    拝啓
                    <br />
                    この度、平成30年にGHインテグレーション株式会社を東京都新宿区に設立致しました。
                    <br />
                    昨今SI、ICT技術者の必要性は増々高まり、人材は逼迫しています。
                    <br />
                    インターネット、SNS、AI、IoT等人類史に残る変革のさなか、人材の不足は企業の成長、経済の発展、
                    社会の進歩等への阻害要因になりつつあります。
                    <br />
                    日本は少子化に加え、長年に亘りIT技術者を冷遇した結果、国内では人材が逼迫し、人材の確保は
                    至難の業となっています。
                    <br />
                    さらにITスキルも多様化し、従来の開発中心から、ビジネスの企画・ソリューションからAIまで、
                    企業活動を行う上でなくてはならないスキルとなっています。
                    <br />
                    この様な環境下、当社は長年ICT業界を牽引し、また国際的な人脈に明るいメンバーを中心に設立いたしました。
                    <br />
                    起業に当たり当社は、ICT人材を世の中に提供し、社会変革の中核として産業界・社会に貢献することを目的としています。
                    <br />
                    併せて、従業員・家族の健康と幸福、地域の皆様の発展にも寄与したいと考えています。
                    <br />
                    皆様方におかれましては、倍旧のお引き立てを賜りますようお願い申し上げます。
                    <br />
                    <div  className="greetingText" style={{textAlign:'right'}} >敬具</div>
                  </span>
                </div>
                <div className="greetingsSign">
                <div className="signTextdiv" style = {{paddingBottom : 10,float : 'right'}} >
                <div className="signText01" style={{paddingBottom : 5,textAlign : 'right'}} >
                  GHインテグレーション株式会社
                    </div>
                    <div className="signText02" style = {{textAlign : 'right'}} >代表取締役　錦織　劉一</div>
                  </div>
                  {/* <div className="signImgdiv">
                    <img src={sign} className="signImg"></img>
                  </div> */}
                </div>
                <p/>
                <div className="work04Img01div">
                  <img src={img01} className="work04Img01" />
                </div>
                <div style={{paddingTop:'4%', marginBottom:'-8%'}}>
                <h2>
                  {' '}
                  <span>
                    <img src={square} style={style.spanStyle}></img>
                  </span>{' '}
                  会社理念
                </h2>
                </div>

                <div className="work04Img02div" style={{paddingBottom:'10%'}}>
                  <img src={img02} className="greetingImg02" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <script></script>
      <div className="top"></div>
    </div>
  )
}

export default Contents
