import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import '../../assets/scss/sub-1.css'
import bannerImg from '../../assets/img/Greetings/Banner.png'
import img01 from '../../assets/img/Work01/work01_01.png'
import img02 from '../../assets/img/Work01/work01_02.jpg'
import img03 from '../../assets/img/Work01/work01_03.png'
import btn from '../../assets/img/Work01/work01btn.png'

import square from '../../assets/img/Work01/square.png'

function Work01contents() {
  const [isActive, setActive] = useState(false)

  const style = {
    logoImg: {
      backgroundImage: `url(${bannerImg})`,
    },

    fontsize: {
      marginTop: '-3%',
      paddingLeft: '4.5%',
      color: '#b3b3b3',
    },
    tableSize: {
      align: 'center',
      borderCollapse: 'collapse',
      marginTop: '6%',
      width: '100%',
      height: '100%',
    },
    spanStyle: {
      width: '2%',
      height: '50%',
      paddingBottom: '1%',
    },
  }

  useEffect(() => {
    setTimeout(() => {
      setActive(true)
    }, 50)
  }, [])

  return (
    <div>
      <Helmet>
        <script type="text/javascript" src="/jquery-3.3.1.js"></script>
        <script
          type="text/javascript"
          src="/jquery.mCustomScrollbar.css"
        ></script>
        <script type="text/javascript" src="/jquery-migrate-1.4.1.js"></script>
        <script
          type="text/javascript"
          src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.1.1/jquery.min.js"
        ></script>
        <script
          type="text/javascript"
          src="https://cdnjs.cloudflare.com/ajax/libs/jquery-cookie/1.4.1/jquery.cookie.js"
        ></script>
        <script type="text/javascript" src="/_sub-side-menu.js"></script>
      </Helmet>
      {/* <section className="subvisu-sec visu-top-gap "> */}
      <section
        className={`subvisu-sec visu-top-gap ${isActive ? 'start' : ''}`}
      >
        <div className="visu-bg" style={style.logoImg}></div>
        <div className="left-bg"></div>
        <div className="right-bg"></div>
        <p>SI Professional Partner</p>
        <h4 style={{ color: 'red' }}>-</h4>
        <h3> 自信と誇りを持って、価値創出を追求するSI企業</h3>
      </section>
      <section className="cont-sec">
        <div className="con-cont">
          <div className="content-box">
            <div className="left-menu-box">
              <dl id="stick" className="left-menu-dl natural">
                <dt>　SI 事業</dt>
                <dd>
                  <a href="/work01">開発</a>
                </dd>
                <dd>
                  <a href="/work02">
                    ネットワーク<br></br>セキュリティ
                  </a>
                </dd>
                <dd>
                  <a href="/work03">モバイル開発</a>
                </dd>
                <dd>
                  <a href="/work04">システム</a>
                </dd>
                <dd>
                  <a href="/work05">データベース</a>
                </dd>
              </dl>{' '}
            </div>
            <div id="contbox" className="right-cont-box">
              <div className="contex-box">
                <h2>
                  <div className="workSquarediv">
                    <img src={square} className="Square"></img>
                  </div>
                  <div className="workTitlediv">
                    {' '}
                    Web×スマホ×業務×組込設計・開発の企画・提案<br></br>
                    設計・開発・テスト～
                  </div>
                </h2>
                <div>
                  <h3 style={style.fontsize}>
                    {' '}
                    Web系×スマホ系×業務系×組込み系アプリなどの
                    企画・提案～開発までものづくりをリードします。
                  </h3>
                </div>
                <div className="work04Img01div">
                  <img src={img01} className="work04Img01" />
                </div>
                <div className="work04Img02div">
                  <img src={img02} className="work04Img02" />
                </div>
                <div className="work04Tablediv">
                  <table style={style.tableSize}>
                    <tr className="workTableCss">
                      <td className="workTablefirsttd" align="center">
                        Python
                      </td>
                      <td className="workTableCss" align="center">
                        PHP
                      </td>
                      <td className="workTableCss" align="center">
                        HTML
                      </td>
                      <td className="workTableCss" align="center">
                        CSS
                      </td>
                      <td className="workTableCss" align="center">
                        Perl
                      </td>
                      <td className="workTablefirsttd" align="center">
                        JavaScript
                      </td>
                    </tr>
                    <tr className="workTableCss">
                      <td className="workTablefirsttd" align="center">
                        GO
                      </td>
                      <td className="workTableCss" align="center">
                        Objective-C
                      </td>
                      <td className="workTableCss" align="center">
                        Swift
                      </td>
                      <td className="workTableCss" align="center">
                        Ruby
                      </td>
                      <td className="workTableCss" align="center">
                        Rails
                      </td>
                      <td className="workTablefirsttd" align="center">
                        Kotlin
                      </td>
                    </tr>
                    <tr className="workTableCss">
                      <td className="workTablefirsttd" align="center">
                        JAVA
                      </td>
                      <td className="workTableCss" align="center">
                        XML
                      </td>
                      <td className="workTableCss" align="center">
                        Tomcat
                      </td>
                      <td className="workTableCss" align="center">
                        MyBatis
                      </td>
                      <td className="workTableCss" align="center">
                        Git
                      </td>
                      <td className="workTablefirsttd" align="center">
                        SPF
                      </td>
                    </tr>
                    <tr className="workTableCss">
                      <td className="workTablefirsttd" align="center">
                        C、C++
                      </td>
                      <td className="workTableCss" align="center">
                        {' '}
                        VC++
                      </td>
                      <td className="workTableCss" align="center">
                        VB
                      </td>
                      <td className="workTableCss" align="center">
                        VBA
                      </td>
                      <td className="workTableCss" align="center">
                        salesforce
                      </td>
                      <td className="workTablefirsttd" align="center">
                        CRM
                      </td>
                    </tr>
                    <tr className="workTableCss">
                      <td className="workTablefirsttd" align="center">
                        ERP
                      </td>
                      <td className="workTableCss" align="center">
                        SAP
                      </td>
                      <td className="workTableCss" align="center">
                        Android
                      </td>
                      <td className="workTableCss" align="center">
                        RPG
                      </td>
                      <td className="workTableCss" align="center">
                        IOS
                      </td>
                      <td className="workTablefirsttd" align="center">
                        COBOL
                      </td>
                    </tr>
                  </table>
                </div>

                <div className="work04Img03div" style={{marginBottom:'-1%'}} >
                    <img src={img03} className="work04Img03" />  
                </div>
                <a href="/contact" >
                <img src={btn} className="workBtn"/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="top"></div>
    </div>
  )
}

export default Work01contents
